





































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
const tabList = [
  {
    name: '生产技术',
    // url: '',
    url: '/api/tobacco/rest_field_class/page',
  },
  {
    name: '病虫害与气象',
    url: '/api/tobacco/rest_disease_pests/page',
  }, {
    name: '病虫情报',
    url: '/api/tobacco/rest_disaster_type/page',
  }, {
    name: '三农政策',
    url: '/api/tobacco/rest_growth_system/page',
  }
]
export default Vue.extend({
  data() {
    return {
      title: '',
      tabActive: 0,
      defaultList: [],
      tabList: tabList,
      tabId: '',

      // tabIndex: 0,
      // type: 0,

      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      tab: 0,
    }
  },
  watch: {
    title() {
      this.onInit()
    },
  },
  async created() {

    await this.getBarList()
    this.onInit()
  },
  methods: {
    changeTab(tab) {

      this.tabId = this.tabList[this.tabActive].id
      this.onInit()

    },
    getBarList() {
      return request.get('/api/column/rest_category/list').then((data) => {
        // console.log(data)
        this.defaultList = data
        // this.tabList = this.defaultList.filter((e: any) => e.keywords === '1')
        this.tabId = this.tabList[this.tabActive].id
      })
    },
    onInit() {
      this.pageNum = 1
      this.getData()
    },
    onLoad() {
      this.pageNum += 1
      this.getData()
    },
    onClick(name) {
      // console.log(name)
      // this.tabId = name
      // this.tabActive = name
      if (this.tab === 0) {
        sessionStorage.setItem(`tabActive1`, name)
      } else if (this.tab === 1) {
        sessionStorage.setItem(`tabActive2`, name)
      }
      this.tabId = this.tabList[name].id
      this.pageNum = 1
      this.getData()
    },
    getData() {
      this.loading = true
      const { pageNum, pageSize, title, tabId } = this
      const params = { pageNum, pageSize, title, categoryId: tabId } as any
      // console.log(params)
      request.get('api/column/article/page', params).then(({ list, total }) => {
        list.map((e) => {
          e.imgUrl = ''
          e.imageUrl = ''
          if (e.categoryId === '1') {
            e.imageUrl = '/static/images/new/tec1.png'
          } else if (e.categoryId === '5') {
            e.imageUrl = '/static/images/new/tec3.png'
          } else if (e.categoryId === '6') {
            e.imageUrl = '/static/images/new/tec2.png'
          } else if (e.image === 'default.png') {
            e.imgUrl = '/static/images/tec.png'
          } else if (e.image) {
            e.imgUrl = 'https://czycwechat.sxktqx.com/columnfiles' + e.image
          }
        })
        if (pageNum == 1) this.list = list
        else this.list = this.list.concat(list)
        this.total = total
        this.loading = false
      })
    },
    view(id: string) {
      this.$router.push(`/classroom_view/${id}`)
      // // doc
      // this.$router.push({
      //     path: '/cxo_view',
      //     query: {url: `/files${url}`},
      // })
      // // editor
      // this.$router.push(`/classroom_view/${id}`)
      // // mp4
      // let baseUrl = ''
      // if (process.env.NODE_ENV=='development') {
      //     baseUrl = 'http://czycwechat.sxktqx.com'
      // }
      // window.location.href = `${baseUrl}/files${url}`
      // // pdf
      // this.$router.push({
      //     path: '/file_view',
      //     query: {url: `/files${url}`},
      // })
    },
  },
  filters: {
    dtFormat(timestamp: number) {
      const time = moment(timestamp)
      return time.format('YYYY-MM-DD')
    }
  }
})
