import { render, staticRenderFns } from "./classroom.vue?vue&type=template&id=680a8849&scoped=true&"
import script from "./classroom.vue?vue&type=script&lang=ts&"
export * from "./classroom.vue?vue&type=script&lang=ts&"
import style0 from "./classroom.vue?vue&type=style&index=0&id=680a8849&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680a8849",
  null
  
)

export default component.exports